import React from "react";
import {globalSigner} from "./SignerHandler";
import {errorMessage, successMessage} from "./AuthInterface";
import {stakingAddress} from "./StakeModule";
import axios from "axios";
import {API_URL} from "./MultiSwapInterface";
import {simulateMouseClick} from "./AddLiquidityInterface";
import {valueOrZero} from "./InvestToPoolInterface";

interface IState{
    sold: number;
    eaglesIds: any;
    userEaglesOwned: any;
    userStakedEagles: any;
    lastCheckInterest: any;
    userLastCheckInterest: any;
    userStakedAmount: any;
    userClaimed: any;
    globalEarned: any
}

interface IProps{
}

export class EaglesInterface extends React.Component<IProps, IState>{

    constructor(props: IProps, state: IState) {
        super(props, state);
        this.state = {
            sold: 76,
            eaglesIds: [],
            userEaglesOwned: [],
            userStakedEagles: [],
            lastCheckInterest: 0,
            userLastCheckInterest: 0,
            userStakedAmount: 0,
            userClaimed: 0,
            globalEarned: 1000 * 1e6
        }
    }

    stakeEagle(assetId: String) {
        globalSigner.signer.invoke({
            dApp: "3PKUxbZaSYfsR7wu2HaAgiirHYwAMupDrYW",
            fee: 500000,
            payment: [
                {
                    "assetId": assetId,
                    "amount": 1
                }
            ],
            call: {
                function: 'stake',
                args: []
            },
            feeAssetId: 'WAVES',
        }).broadcast().then((tx: any) => successMessage("Congrats! You staked the Early Eagle!")).catch((tx: any) => errorMessage(JSON.stringify(tx)));
    }

    unstakeEagle(assetId: String) {
        globalSigner.signer.invoke({
            dApp: "3PKUxbZaSYfsR7wu2HaAgiirHYwAMupDrYW",
            fee: 500000,
            payment: [],
            call: {
                function: 'unStake',
                args: [{"type": "string", "value": assetId}]
            },
            feeAssetId: 'WAVES',
        }).broadcast().then((tx: any) => successMessage("Congrats! You unstaked the Early Eagle!")).catch((tx: any) => errorMessage(JSON.stringify(tx)));
    }

    claimRewards() {
        globalSigner.signer.invoke({
            dApp: "3PKUxbZaSYfsR7wu2HaAgiirHYwAMupDrYW",
            fee: 500000,
            payment: [],
            call: {
                function: 'claimReward',
                args: []
            },
            feeAssetId: 'WAVES',
        }).broadcast().then((tx: any) => successMessage("Congrats! You unstaked the Early Eagle!")).catch((tx: any) => errorMessage(JSON.stringify(tx)));
    }


    getArt() {
        globalSigner.signer.invoke({
            dApp: "3P7FFQkgALLZDJpCf3smY517wjgPMswGoRP",
            fee: 500000,
            payment: [
                {
                    "assetId": 'HEB8Qaw9xrWpWs8tHsiATYGBWDBtP2S7kcPALrMu43AS',
                    "amount": 77 * 10**8
                }
            ],
            call: {
                function: 'buyArt',
                args: []
            },
            feeAssetId: 'WAVES',
        }).broadcast().then((tx: any) => successMessage("Congrats! You bought the Early Adopter Eagle!")).catch((tx: any) => errorMessage(JSON.stringify(tx)));
    }

    get availableToClaim() {
        return Math.floor(this.state.userStakedAmount * (this.state.lastCheckInterest - this.state.userLastCheckInterest) / 1e16) / 1e2
    }

    get claimed() {
        return Math.floor(this.state.userClaimed / 1e4) / 1e2
    }

    get apy() {
        const wouldEarn = Math.floor(this.state.lastCheckInterest / ((new Date().getTime() - 1643279145659) / 1e3) * 365 * 24 * 60 * 60 / 1e4) / 1e2
        return ((wouldEarn / 2000 - 1) * 100) + "%"
    }

    get totalEarned() {
        return Math.floor(this.state["globalEarned"] / 1e4) / 1e2
    }

    async componentDidMount() {
        axios.get(`${API_URL}/addresses/data/3P7FFQkgALLZDJpCf3smY517wjgPMswGoRP/eagles_sold`).then((result) => {
                this.setState({sold: result.data["value"]});
            }
        )

        axios.get(`${API_URL}/addresses/data/3P7FFQkgALLZDJpCf3smY517wjgPMswGoRP`).then((result) => {
                const eaglesIds = []
                for (const n of result.data) {
                    if (n["value"] == "eagle") { eaglesIds.push(n["key"].split("_")[1]) }
                }
                this.setState({eaglesIds: eaglesIds});
            }
        )

        window.setInterval(() => {
            // axios.get(`${API_URL}/addresses/data/3P7FFQkgALLZDJpCf3smY517wjgPMswGoRP/eagles_sold`).then((result) => {
            //         this.setState({sold: result.data["value"]});
            //     }
            // )

            const userAddress = localStorage.getItem("userAddress")

            axios.get(`${API_URL}/addresses/data/3PKUxbZaSYfsR7wu2HaAgiirHYwAMupDrYW`).then((result) => {
                const userStakedEagles = []
                for (const d of result.data) {
                    if (d.value == userAddress && d.key.indexOf("owner") > -1) {
                        userStakedEagles.push({assetId: d.key.split("_")[0], name: "Golden Eagle"})
                    }
                    if (d.key == "global_DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p_earnings") {
                        this.setState({globalEarned: d.value})
                    }
                    if (d.key == "global_lastCheck_DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p_interest") {
                        this.setState({lastCheckInterest: d.value})
                    }
                    if (d.key == userAddress+"_lastCheck_DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p_interest") {
                        this.setState({userLastCheckInterest:  d.value})
                    }
                    if (d.key == userAddress+"_staked") {
                        this.setState({userStakedAmount: d.value})
                    }
                    if (d.key == userAddress+"_DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p_claimed") {
                        this.setState({userClaimed: d.value})
                    }
                }
                for (const d of result.data) {
                    if (d.key.indexOf("_name") > -1) {
                        const assetId = d.key.split("_")[0]
                        for (const n in userStakedEagles) {
                            if (userStakedEagles[n]["assetId"] == assetId) {
                                userStakedEagles[n]["name"] = "Golden Eagle " + d.value
                            }
                        }
                    }
                }
                this.setState({userStakedEagles: userStakedEagles});
            })

            if (userAddress) {
                axios.get(`${API_URL}/assets/nft/${userAddress}/limit/1000`).then((result) => {
                    const userEaglesOwned = []
                    for (const nft of result.data) {
                        if (this.state.eaglesIds.indexOf(nft.assetId) > -1) {
                            userEaglesOwned.push({
                                assetId: nft.assetId,
                                name: "Golden Eagle " + nft.description.split("\n")[4].split(" ")[2]
                            })
                        }
                    }
                    this.setState({userEaglesOwned: userEaglesOwned})
                })
            }
        }, 1000)
    }

    render() {
        return <div className="eaglesBlock">
            <h1>Ultra Staking</h1>
            <div className="desc">EARLY BIRDS PROGRAM</div>

            <div className="blockBody">
                <div>Total earned by Eagles: <strong>{this.totalEarned} USDN</strong></div>
                <div><a target="_blank" href="https://mainnet.sign-art.app/user/3PFTZA987iHHbP6UWVTbbrquNetcFSULqUP/artwork/DxJAxqQhWAojdnzmcZpHAE3Hbm39JPAaCq9rEMJqNn61">Get Eagle at Sign Art</a></div>
            </div>

            <div className="">

                {(localStorage.getItem("userAddress") == undefined) ? (
                    <button className="bigButton" onClick={() => {simulateMouseClick(document.getElementById("login")!)}}>Connect wallet</button>
                ) : (
                    <div className="info-block flex blockBody">
                        {/*<div className="columnFlex">*/}
                        {/*    <div className="dollarValue">Eagle owned</div>*/}
                        {/*    <div className="value">{this.state.userEaglesOwned.length + this.state.userStakedEagles.length}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="columnFlex">
                            <div className="dollarValue">You earned</div>
                            <div>
                                {Math.round(this.claimed * 1e2 + this.availableToClaim * 1e2) / 1e2} USDN
                            </div>
                        </div>

                        <div className="columnFlex">
                            <div className="dollarValue">Available to claim</div>
                            <div>
                                {this.availableToClaim} USDN
                            </div>
                        </div>

                            <button className="smallButton" onClick={this.claimRewards}>Claim</button>
                    </div>
                )}

                <div>
                    {(this.state.userEaglesOwned.length) ? (
                        <h2>Your eagles</h2>
                        ) : (
                        <h2></h2>
                        )}

                    <div className="info-block">
                    {
                        this.state.userEaglesOwned.map((eagle: any) =>
                            (<div className="flex">
                                <img className="eagleIcon" src="https://bafybeih3bj2c5msxiazqgdjqxosrqd3fweutquomn4zs4pcgnbjsfl5si4.ipfs.infura-ipfs.io/display.jpeg" alt=""/>
                                <span className="eagleName">{eagle.name}</span>
                                <button className="smallButton" onClick={() => {this.stakeEagle(eagle.assetId)}}>Stake</button>
                            </div>)
                        )
                    }
                    </div>

                    {(this.state.userStakedEagles.length) ? (
                        <h2>Staked eagles</h2>
                    ) : (
                        <h2></h2>
                    )}

                    <div className="info-block">
                    {
                        this.state.userStakedEagles.map((eagle: any) =>
                            (<div className="flex">
                                <img className="eagleIcon" src="https://bafybeih3bj2c5msxiazqgdjqxosrqd3fweutquomn4zs4pcgnbjsfl5si4.ipfs.infura-ipfs.io/display.jpeg" alt=""/>
                                <span className="eagleName">{eagle.name}</span>
                                <button className="smallButton" onClick={() => {this.unstakeEagle(eagle.assetId)}}>Unstake</button>
                            </div>)
                        )
                    }
                    </div>
                </div>
            </div>




            {/*<div className="desc">*/}
            {/*    🦅 Eagle NFTs are supposed to favor early adopters of Puzzle Swap DeFi ecosystem. <br/>*/}
            {/*    🎨 This is the first collection of artefacts launched in collaboration with Sign&nbsp;Art. <br/>*/}
            {/*    💰 Purchased NFTs will be available to trade at <a href="https://mainnet.sign-art.app/user/3PFTZA987iHHbP6UWVTbbrquNetcFSULqUP" target="_blank">Sign&nbsp;Art</a> secondary market and use for ultra staking. <br/>*/}
            {/*    ‍🔥 All collected PUZZLE are immediately burned to decrease token circulation. <br/>*/}
            {/*</div>*/}
            {/*<div className="">*/}
            {/*    <div>*/}
            {/*        <img className="eagleImage" src="https://bafybeih3bj2c5msxiazqgdjqxosrqd3fweutquomn4zs4pcgnbjsfl5si4.ipfs.infura-ipfs.io/display.jpeg" alt=""/>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <div className="eaglesLeft">*/}
            {/*            <i>Unique peace of art created by @eattheart23</i>*/}
            {/*            <br/>*/}
            {/*            <strong>77 PUZZLE</strong>*/}
            {/*            <br/>*/}
            {/*            {76 - this.state.sold} eagles left*/}
            {/*        </div>*/}
            {/*        <button>SOLD OUT !!! 💜</button>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    }
}